/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

// UI lib comoponents

// Local UI components

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Button({ children, isSubmit, navigation = '' }) {
  /* ******************************** RENDERING ******************************* */

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className="button"
      onClick={navigation !== '' ? () => navigate(navigation) : null}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isSubmit: PropTypes.bool,
  navigation: PropTypes.string.isRequired,
};

Button.defaultProps = {
  isSubmit: false,
};

export default Button;
