/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import Button from '../../../shared/UIKit/Button';
// APIs

import { API_UPLOADS, API_FINANCIAL_STATEMENT } from '../../../shared/APIs';
// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function FinancialStatemetsTable() {
  /* ******************************** HOOKS ******************************* */
  // State
  const [financialData, setFinancialData] = useState();
  // Localisation
  const { language } = useI18next();
  const { t } = useTranslation();
  /* ------------------------------ HELPERS --------------------------------- */
  async function getFinancialStatements() {
    const { data } = await axios(API_FINANCIAL_STATEMENT(language));
    setFinancialData(data);
  }
  // Effect
  useEffect(() => {
    getFinancialStatements();
  }, []);

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */
  const { tableHeaderCol, buttonText } = t('financialStatement', {
    returnObjects: true,
  });

  /* ******************************** RENDER HELPERS ******************************* */

  const TableHeaderCols = tableHeaderCol?.map(({ id, title }) => (
    <Col
      key={id}
      order={{ md: 2, xl: 1 }}
      className="financial-statemets__table-header-col"
      debug
    >
      {title}
    </Col>
  ));

  const Tablebody = financialData?.data.map(({ id, attributes }) => {
    const { year, report } = attributes;
    return (
      <Row className="financial-statemets__table-content" debug>
        <Col
          key={id}
          order={{ md: 2, xl: 1 }}
          className="financial-statemets__table-content-col"
          debug
        >
          {year}
        </Col>
        <Col
          className="financial-statemets__table-button"
          order={{ md: 4, xl: 1 }}
          debug
        >
          <Button navigation={API_UPLOADS(report?.data[0]?.attributes?.url)}>
            {buttonText}
          </Button>
        </Col>
      </Row>
    );
  });

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="financial-statemets__table">
      <Row className="financial-statemets__table-header" debug>
        {TableHeaderCols}
      </Row>
      <br />
      {Tablebody}
      <br />
    </Container>
  );
}

FinancialStatemetsTable.propTypes = {};

FinancialStatemetsTable.defaultProps = {};

export default FinancialStatemetsTable;
